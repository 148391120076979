<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card elevation="0">
                    <v-card-title>Import Trades</v-card-title>
                    <v-card-subtitle>Past trades here to import</v-card-subtitle>
                    <v-card-text>

                        <div style="max-width: 300px;">
                            <v-select label="Select Data Source" outlined dense width="300px" v-model="form.import_type" :items="sources"></v-select>
                        </div>

                        <div v-if="form.import_type == 'TraderSync'">
                            <div style="max-width: 300px;" >
                                <v-text-field label="TraderSync Username" outlined dense width="300px" v-model="form.username"></v-text-field>
                            </div>
                        </div>

                        <div v-if="form.import_type == 'Hari CSV'">
                           <v-textarea outlined v-model="form.content" rows="20"></v-textarea>                        
                        </div>

                    </v-card-text>
                    <v-card-actions>
                        
                        <v-btn  elevation="0" large color="primary" @click="importTrades">Import Trades</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
     name: 'LoadTrades',
     data: () => ({
         form: {
             username: '',
             trades: '',
             import_type: ''

         },
         sources: ['TraderSync', 'Hari CSV', "JSON"],
         traders: []
     }),
     created(){
      
     },
    methods:{
      
        async importTrades(){
            this.api.post(`/backtest/trades`, this.form); 

        }
    }
    

}
</script> 