<template>
    <div>
        <v-container fluid> 
            <v-card elevation="0">
                <v-row>
                    <v-col><h2>Parse Channel Messages</h2></v-col>
                </v-row>
                <v-row>
                
                    <v-col style="flex: 0 1 250px;"><v-autocomplete label="Trader" item-value="id" item-text="name"  @change="loadMessages" dense outlined :items="formattedTraders"  v-model="trader_id"></v-autocomplete></v-col>
                    <v-col style="flex: 0 1 250px;"><v-select label="Type" dense outlined :items="types" v-model="type"></v-select></v-col>
                    
                </v-row>
            </v-card>
            <v-card elevation="0" v-if="trader_id" >
                
                <v-card-title>{{activeTrader.name}}</v-card-title>
                <v-card-subtitle>
                    {{activeTrader.trade_count}} total trades taken.<br />
                    {{activeTrader.open_count}} open trades remaining.
                </v-card-subtitle>
                

                <v-card-text class="mt-5" v-if="trades.length">
                     <v-container fluid v-for="(trade) in trades" :key="trade.id" class="mb-5" style="border: 1px solid #e2e2e2;"> 
                        <v-row class="mb-10">
                            <v-col class="text-bold"><h2>{{trade.Ticker.symbol}}</h2></v-col>
                            <v-col class="text-right">
                                <v-btn @click="markAbandoned(trade)" elevation="0" color="error" outlined class="mr-3" >No Exit Found</v-btn>
                            
                                <v-btn @click="markNotATrade(trade)" elevation="0" color="error" >Not A Trade</v-btn>
                            </v-col>
                        </v-row>
                        
                        <v-row class="secondary white--text">
                            <v-col style="flex: 0 1 100px">ID</v-col>
                            <v-col style="flex: 0 1 200px">Time</v-col>
                            <v-col style="flex: 0 1 100px">Action</v-col>
                           <v-col style="flex: 1 0 300px">Message</v-col>
                            <v-col style="flex: 0 1 200px">Status</v-col>
                            <v-col style="flex: 0 1 100px">Replied To</v-col>    
                            <v-col style="flex: 0 1 100px">Is Trade</v-col>    
                            <v-col style="flex: 0 1 200px">Actions</v-col>
                        </v-row>

            
                            <v-row v-for="(message, i) in trade.PotentialCloses" :key="message.id" class="msg-row" :class="i == 0 ? 'opeining-trade': ''">
                                <v-col style="flex: 0 1 100px">{{ message.id }}</v-col>
                                <v-col style="flex: 0 1 200px">{{ message.time }}</v-col>
                                <v-col style="flex: 0 1 100px">{{ message.action }}</v-col>
                                <v-col style="flex: 1 0 300px">{{ message.raw_message }}</v-col>
                                <v-col style="flex: 0 1 200px">{{ message.status_text }}</v-col>
                                <v-col style="flex: 0 1 100px">{{ message.replied_to_message_id }}</v-col>
                                  <v-col style="flex: 0 1 100px">{{ message.is_trade }}</v-col>    
                                <v-col style="flex: 0 1 200px">
                                    <v-btn @click="markAsExit(trade.id, message.id)" small outlined v-if="i > 0" elevation="0" color="primary" >Mark As Exit</v-btn>
                                </v-col>
            
                            </v-row>
                                <!-- <v-divider :key="message.id + 'd'"></v-divider> -->
                         
                     </v-container>
                </v-card-text>
                <v-card-text class="mt-5" v-else style="background: #F0F8FF; text-align: center; padding: 100px;" elevation="0">
                    No Open Trades
                </v-card-text>
            </v-card>
            
        </v-container>
        
    </div>    
</template>

<script>
    const { DateTime } = require("luxon");

    export default {
        name: 'ChannelMessages',

        data: () => ({
            type: 'closing_trade',
            types: [{
                value: 'closing_trade',
                text: 'Closing Trades'
            },
            {
                value: 'multiple_tickers',
                text: 'Multiple Tickers'
            }],
            trader_id: '', 
            traders: [],
            trades: []
        }),
        created(){
            this.getTraders();

        },
        computed:{
            activeTrader(){
                if(!this.trader_id) return {}
                let trader = this.traders.find(t => t.id == this.trader_id);
                return trader;
               
            }, 
            formattedTraders(){
                return this.traders.map(t => {
                    return {
                        id: t.id, 
                        name: `${t.name} (${t.open_count})` 
                    }
                })
            },
            activeTrade(){
                return this.trades[0]
            }
        }, 
        methods:{
            async getTraders(){
                let response = await this.api.get(`channels/traders`, {channel_id: 1});
                
                this.traders = response.traders
            }, 
            async loadMessages(){
               
                let response = await this.api.get(`channels/triage`, {
                    trader_id: this.trader_id,
                    type: this.type
                });
                this.trades = response.trades;
            }, 
            async markAsExit(trade_id, message_id){
                let response = await this.api.get(`channels/trades/${trade_id}/mark-exit/${message_id}`);
                await this.loadMessages();
                await this.getTraders();
            
            },

            async markNotATrade(trade){
                let response = await this.api.delete(`channels/trades`, trade.id);
                await this.loadMessages();
                await this.getTraders();
            
            },

            async markAbandoned(trade){
                 let response = await this.api.put(`channels/trades/${trade.id}/abandon`);
                await this.loadMessages();
                await this.getTraders();
            }

        }
    }
</script>
<style>

    .msg-row{
        border-bottom: 1px solid #e2e2e2;
    }
    .msg-row:nth-of-type(odd){
        background: #f5f5f5; 
    }

    .msg-row.opeining-trade{
        background: #F0F8FF; 
    }

</style>
